.hero-container {
  /* Content over particles.js  */
  position: absolute;
  z-index: 1;
  height: 600px;
  top: 10px;

  /* Aligning hero section */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 200px;
}

/* Main and sub headings */
.hero pre {
  color: #fff;
  font-weight: bold;
  letter-spacing: 2px;
}

/*name and name span */
.name {
  color: #ff748a;
  font-size: 3rem;
}
.hero #hero-main {
  font-size: 2.3rem;
  padding: 0;
  margin: 0;
}

/* about heading */
.hero #hero-sub {
  color: #fff;
  font-size: 1.5rem;
  margin: 1.6rem 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .hero-container {
    height: 350px;
    margin-left: 10px;
  }
  .hero #hero-main {
    font-size: 1.9rem;
  }
  .hero #hero-sub {
    font-size: 0.9rem;
  }
  .hero pre {
    letter-spacing: 0.8px;
  }
  #name {
    color: #ff748a;
    font-size: 2.4rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .hero-container {
    height: 540px;
    margin-left: 30px;
  }
  .hero #hero-main {
    font-size: 1.9rem;
  }
  .hero #hero-sub {
    font-size: 0.9rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .hero-container {
    height: 600px;
    margin-left: 30px;
  }
  .hero #hero-main {
    font-size: 2.3rem;
  }
  .hero #hero-sub {
    font-size: 1.3rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .hero-container {
    height: 400px;
    margin-left: 30px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .hero-container {
    height: 600px;
    margin-left: 230px;
  }
}
