/* container */
.skills-container {
  height: fit-content;
  width: 100%;
  background-color: #24215a;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.skill-list-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Skills heading container and heading */
.section-heading {
  height: 80px;
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading {
  color: #fff;
  font-size: 1.9rem;
  letter-spacing: 1px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  text-align: center;
  padding: 0;
  margin: 0;
}

/* Skills Table List */
table {
   display: flex; 
width: 60%;

}

.table-row{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
 margin: 20px 0 50px 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 1000px) {

  .skills-container {
    height: 550px;
 
  }

 
}



