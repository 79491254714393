.recent-project-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
 
}

.recent-project-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: fit-content;
  /* width: 400px; */
}

.project-img {
  height: 250px;
  width: 350px;
  object-fit: cover;
  cursor: pointer;
}

.project-description {
  text-align: center;
  padding: 0 15px;
}

.project-description p {
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 0.9rem;
  color: #ff748a;


}

.project-description a {
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 0.9rem;
  color: #0f0f0f;


}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .project-img {
    height: 210px;
    width: 280px;
    cursor: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .project-description {
    width: 50%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1500px) {
  .recent-project-container {
    height: 650px;
  }

  .project-description {
    width: 70%;
  }
}
