.data-structures-container {
  width: 100%;
  min-height: 305px;
  background-color: #24215a;
  text-align: center;
}

.data-structure {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 100px;
  width: 100%;


}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .data-structure {
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
  }

  .sub-heading {
    margin: 0;
    padding-bottom: 0.5rem;
  }

  .data-structures-container {
    min-height: 345px;
  }
}
