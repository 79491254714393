.recent-projects-container {
  border-top: 1.5px solid #000;
}

.recent-project-heading {
  color: #ff748a;
  text-align: center;
  font-size: 2.6rem;
  padding-top: 1.5rem;
  letter-spacing: 2px;
}

.recent-projects {
  display: flex;
  max-width: 2500px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .recent-projects {
    flex-direction: column;
  }
  .recent-project-heading {
    font-size: 1.8rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .recent-projects {
    flex-direction: column;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .recent-projects {
    flex-direction: column;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* .recent-projects {
    flex-direction: row;
  } */
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1500px) {
  .recent-projects {
    flex-direction: row;
  }
}
