.footer-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 200px;
  background-color: #24215a;
}

.footer-copy {
  text-align: center;
  letter-spacing: 1px;
  color: #fff;
}
