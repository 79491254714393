form {
  display: flex;
  flex-direction: column;
}

/* Contact form sizes */
.contact-input {
  min-height: 25px;
  font-size: 16px;
}
#contacts-message {
  min-height: 100px;
  max-height: 150px;
  font-size: 16px;
}

/* Submit btn */
.form-btn {
  padding: 6px;
  border-radius: 5px;
  color: #fff;
  font-size: 0.9rem;
  border: 1px solid #000;
  cursor: pointer;
  background-color: #ff748a;
  width: fit-content;
  letter-spacing: 1px;
  margin: 0.5em auto;
}
.form-btn:hover {
  opacity: 0.8;
  transition: 0.2s;
}

/* Heading 'lets talk' */
.contact-me-heading {
  color: #ff748a;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.contact-success-msg {
  padding-bottom: 0.5rem;
  margin: 0;
  text-align: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .contact-input {
    min-width: 250px;
    max-width: 300px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .contact-input {
    min-width: 550px;
    max-width: 550px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .contact-input {
    min-width: 750px;
    max-width: 750px;
  }
}
