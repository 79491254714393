.contact-container {
  min-height: 480px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Self portrait */
.self-portrait-container {
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.self-portrait {
  height: 140px;
  width: 140px;
  margin-bottom: 0.5em;
  border-radius:50px;
}

.contact-socials a {
  margin: 0.5em;
  font-size: 1.5rem;
}
.contact-socials a:hover {
  color: #ff748a;
  transition: 0.5s;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .contact-container {
    flex-direction: column;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .contact-container {
    flex-direction: column;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .contact-container {
    flex-direction: row;
  }
}
