.about-me-container {
  width: 100%;
  height: 650px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .about-me-container {
    background-image: none;
    flex-direction: column-reverse;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .about-me-container {
    flex-direction: column;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .about-me-container {
    flex-direction: row;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {
} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {
} */
