.hero-btn {
  padding: 9px;
  margin-right: 10px;
  border-radius: 5px;
  color: #000;
  font-size: 0.9rem;
  border: none;
  cursor: pointer;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

/* work btn */
#btn-work {
  background-color: #ff748a;
  color: white;
  letter-spacing: 1px;
}
#btn-work:hover {
  border: 1px solid #fff;
  color: #000;
}

/* Contact btn */
#btn-contact {
  border: 1px solid #ff748a;
  color: #ff748a;
  background-color: #fff;
}

#btn-contact:hover {
  border: 1px solid #000;
  color: #fff;
  background-color: #ff748a;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .hero-btn {
    font-size: 0.8rem;
  }
}
