.icon{
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon img {
  height: 95px;
  width: 95px;
  border-radius: 10px;
}


.icon img:hover {
  animation: shake 0.62s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

.icon-name-container pre {
  font-size: .9rem;
  font-weight: bold;
  color: #ff748a;
  margin: 3px;
  padding: 5px;
  text-align: center;
  letter-spacing: 1px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .icon img {
    height: 75px;
    width: 75px;
  }
}
