.data-stucture-container {
  margin: 0.2rem;


}
.data-structure-link {
  text-decoration: none;

}

.data-structure-name {
  font-size: 1.3rem;
  color: #ff748a;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 1px;
  background-color: white;
  padding: .5rem;
  border-radius: 5px;

  
}

.data-structure-name:hover {
  color: #24215a;
  cursor: pointer;
  transition: 0.4s;
}



